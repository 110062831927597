<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="datatable.queries.per_page"
                      class="ml-1 mr-1"
                      size="sm"
                      :options="[10, 15, 25, 50, 100]"
                      @change="getAgencies"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="datatable.queries.search"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
            </div>
            <template v-if="!loadingAgencies">
              <div class="table-responsive mb-0">
                <b-table
                  :items="agencies"
                  :fields="datatable.columns"
                  :sort-by.sync="datatable.queries.sort_by"
                  :sort-desc.sync="datatable.queries.sort_desc"
                  :no-local-sorting="true"
                  @sort-changed="getAgencies"
                >
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(active)="">
                    <feather type="check" class="icon-dual-success" />
                  </template>
                  <template v-slot:cell(actions)="data">
                    <b-button
                      variant="link"
                      size="sm"
                      title="Restore"
                      class="btn btn-link btn-action"
                      :disabled="loadingRestore !== null"
                      @click="restore(data.item.id)"
                    >
                      <b-spinner v-if="loadingRestore === data.item.id" small />
                      <i v-else class="uil uil-refresh"></i>
                    </b-button>
                    <b-button
                      variant="link"
                      size="sm"
                      title="Delete"
                      class="ml-1 btn btn-link btn-action text-danger"
                      :disabled="loadingRemove === data.item.id"
                      @click="remove(data.item)"
                    >
                      <b-spinner v-if="loadingRemove === data.item.id" small />
                      <i v-else class="uil uil-trash-alt"></i>
                    </b-button>
                  </template>
                </b-table>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ agencies.length }} agencies
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                        v-model="datatable.queries.page"
                        :total-rows="agencies.length"
                        :per-page="datatable.queries.per_page"
                        @change="onPageChanged"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="showDeleteModal" title="Delete agency">
      <p v-if="selectedAgency"
        >Are you sure you want to delete agency "{{ selectedAgency.name }}"?</p
      >
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false"
          >Cancel</b-button
        >
        <b-button variant="danger" @click="handleRemove">Delete</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      datatable: {
        columns: [
          { key: 'id', label: '#', sortable: true },
          { key: 'name', sortable: true },
          { key: 'owner.email', label: 'Email', sortable: true },
          { key: 'actions', class: 'actions' },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'archived',
          search: null,
          sort_by: 'id',
          sort_desc: false,
        },
      },
      loadingAgencies: false,
      loadingRestore: null,
      loadingRemove: null,
      showDeleteModal: false,
      selectedAgency: null,
    }
  },

  computed: {
    agencies() {
      return this.$store.getters['agency/all'] || []
    },

    totalAgencies() {
      return this.$store.getters['agency/total']
    },
  },

  watch: {
    'datatable.queries.search': function(val) {
      this.getAgencies()
    },
  },

  created() {
    this.$store.dispatch('agency/setCurrentPage', 'archived')
  },

  mounted() {
    if (this.$store.getters['agency/all'] === null) {
      this.getAgencies()
    } else {
      this.datatable.queries = this.$store.getters['agency/queries']
    }
  },

  methods: {
    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getAgencies()
    },

    getAgencies() {
      this.loadingAgencies = true

      this.$store
        .dispatch('agency/getAll', {
          queries: this.datatable.queries,
          page: 'archived',
        })
        .then(() => {
          this.loadingAgencies = false
        })
        .catch(() => {
          this.loadingAgencies = false
        })
    },

    restore(agencyId) {
      this.loadingRestore = agencyId

      this.$store
        .dispatch('agency/restore', agencyId)
        .then((res) => {
          this.loadingImpersonate = null
          this.getAgencies()
        })
        .catch(() => {
          this.loadingImpersonate = null
        })
    },

    remove(agency) {
      this.showDeleteModal = true
      this.selectedAgency = agency
    },

    handleRemove() {
      this.showDeleteModal = false
      this.loadingRemove = this.selectedAgency.id
      this.$store
        .dispatch('agency/remove', this.selectedAgency.id)
        .then(() => {
          this.loadingRemove = null
          this.getAgencies()
        })
        .catch(() => {
          this.loadingRemove = null
        })
    },
  },
}
</script>
